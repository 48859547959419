import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import WallpaperSection from '../components/wallpaperSection'

const WallpapersPage = () => (
    <Layout>
        <SEO title="wallpapers:title" description="wallpapers:paragraph" />
        <WallpaperSection />
    </Layout>
)

export default WallpapersPage
